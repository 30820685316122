import React, { useState, useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';

import classes from './Search.module.css';

const SearchBox = ({ data }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    const results = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(results);
  }, [searchQuery, data]);

  return (
    <div>
      {/* <input
        type="text"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        placeholder="Search..."
      /> */}
       <form class={classes.forminput} action="action_page.php">
  <input type="text"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        placeholder="Search..."/>
  <button type="submit"><SearchIcon/></button>
</form>
      <ul>
        {searchQuery && filteredData.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default SearchBox;
