// TickerFeed.jsx
import React, { useEffect, useState } from 'react';
import Ticker from 'react-ticker';

// import './Ticker.css';

const TickerFeed = () => {
  
  let state1= " ابو الشنب فوودز! ";
  let state2= "   ";
  let state3= " ابو الشنب فوودز! ";


return (
      <div className="ticker">
         <div className="ticker__field">
            <Ticker offset="run-in">
               {() => 
               <><p>{state1} {state2} {state3}</p></>
                  
               }
            </Ticker>
         </div>
      </div>
   );
};
export default TickerFeed;