import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from '../../../Common/auth-context'

import classes from "./Cards.module.css";
import Logo from "../../../assets/MetaribaBlueLogo.png";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import WifiIcon from "@mui/icons-material/Wifi";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Footer from "../FooterSection";

const Cards = () => {
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  let logoposition = authCtx.Logo;

  const backhandler = () => {
    navigate('/');
  }

  return (
    <div className={classes.main_container}>
      <div className={classes.heading}>
        <img src={Logo} alt="" className={logoposition === "left" ? `${classes.logo_left}` : `${classes.logo_none}`} />
        <img src={Logo} alt="" className={logoposition === "middle" ? `${classes.logo_middle}` : `${classes.logo_none}`} />
        <img src={Logo} alt="" className={logoposition === "right" ? `${classes.logo_right}` : `${classes.logo_none}`} />
        <div className={classes.close} onClick={backhandler}>
        <ArrowBackIcon />
      </div>
      </div>
      <div className={classes.menu}>
        <h1 className={classes.menu_header}>ابو الشنب فوودز</h1>
        <p className={classes.menu_location}>
          <PlaceIcon /> ابو الشنب فوودز
          <PhoneIcon /> +96171527880
        </p>
        <p className={classes.menu_wifi}>
          <WifiIcon /> ابو الشنب فوودز
        </p>

        {authCtx.theMenu.map((item) => {
          return (
            <article className={classes.meals_list}>
              <img src={item.image} alt="" />
              <h1 className={classes.meals_list_name}>{item.name}</h1>
              <h3 className={classes.meals_list_price}></h3>
              <h5 className={classes.meals_list_content}>
                {" "}
                hello everyone how are you all?
              </h5>
              
            </article>
          );
        })}
        <Footer />
      </div>
    </div>
  );
};

export default Cards;
