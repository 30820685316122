import React, { useContext, useState } from "react";
import AuthContext from "../../Common/auth-context";

import classes from "./menu.module.css";
// import Logo from "../../assets/AbuShanabFoodsWhite.png";
import Logo from "../../assets/AbuShanabFoods.png";
import burger from "../../assets/burger.jpeg";
import pizza from "../../assets/pizza.jfif";
import meat from "../../assets/meat.jfif";
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import WifiIcon from "@mui/icons-material/Wifi";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchBox from "./Searchbox/Search";
import TickerFeed from "./ScrollingTicker/Ticker";
import Footer from "./FooterSection";

export const Sandwishes = [
  {
    key: 1,
    name: "Burgers",
    image: burger,
  },
  {
    key: 2,
    name: "Hotdog",
    image: burger,
  },
  {
    key: 2,
    name: "Sub",
    image: burger,
  },
  {
    key: 2,
    name: "Lebanese Taste",
    image: burger,
  },
  {
    key: 2,
    name: "Zinger",
    image: burger,
  },
  {
    key: 2,
    name: "Meal",
    image: burger,
  },
];
// American 2.70$
// Lebanese 2.70$
// mozzarella 3.85$
// Chicken escalope 3.20$
// Mushroom 3.50$

export const Plates = [
  {
    key: 1,
    name: "Lebanese Pizza",
    image: pizza,
  },
  {
    key: 2,
    name: "Italian Pizza",
    image: pizza,
  },
];

export const Beverages = [
  {
    key: 1,
    name: "Beverages",
    image: meat,
  },
];

const MenuDemo = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [activeButton, setActiveButton] = useState("Sandwishes");
  const [menu, SetMenu] = useState(Sandwishes);

  authCtx.setLogo("middle");

  let logoposition = authCtx.Logo;

  const Cardshandler = () => {
    authCtx.settheMenu(menu);
    navigate("/menu/cards");
  };

  return (
    <div className={classes.main_container}>
      <div className={classes.heading}>
        <img src={Logo} alt="" className={logoposition === "left" ? `${classes.logo_left}` : `${classes.logo_none}`} />
        <img src={Logo} alt="" className={logoposition === "middle" ? `${classes.logo_middle}` : `${classes.logo_none}`} />
        <img src={Logo} alt="" className={logoposition === "right" ? `${classes.logo_right}` : `${classes.logo_none}`} />
        <div className={classes.close} onClick={()=>navigate("/")}>
        {/* <ArrowBackIcon /> */}
      </div>
      </div>
      <div className={classes.menu}>
        <h1 className={classes.menu_header}>ابو الشنب فوودز</h1>
        <p className={classes.menu_location}>
           ابو الشنب فوودز <PlaceIcon />
           0096171527880 <PhoneIcon />
        </p>
        <p className={classes.menu_wifi}>
        </p>
        <TickerFeed />
        {/* <Swiper
          slidesPerView={4}
          spaceBetween={3}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className={classes.mySwiper}
        > */}
          {/* <SwiperSlide > */}
          <div className={classes.swiper_options}>
            <div
            onClick={() => {
                SetMenu(Sandwishes);
                setActiveButton("Sandwishes")
              }}
              className={activeButton === "Sandwishes" ? `${classes.option_active}` :`${classes.option}`}
              
            >
              <div className={classes.option_imgs}>
                <img src={burger} alt="" width="100%" height="100%" />
              </div>
              <div>
                <p>Sandwishes</p>
              </div>
            </div>
          {/* </SwiperSlide> */}
          {/* <SwiperSlide> */}
            <div
            onClick={() => {
                SetMenu(Plates);
                setActiveButton("Plates")
              }}
              className={activeButton === "Plates" ? `${classes.option_active}` : `${classes.option}`}
              
            >
              <div className={classes.option_imgs}>
                <img src={meat} alt="" width="100%" height="100%" />
              </div>
              <div>
                <p>Plates</p>
              </div>
            </div>
          {/* </SwiperSlide> */}
          {/* <SwiperSlide> */}
            <div
            onClick={() => {
                SetMenu(Beverages);
                setActiveButton("Beverages")
              }}
              className={activeButton === "Beverages" ? `${classes.option_active}` : `${classes.option}`}
            >
              <div className={classes.option_imgs}>
                <img src={pizza} alt="" width="100%" height="100%" />
              </div>
              <div>
                <p>Beverages</p>
              </div>
            </div>
          {/* </SwiperSlide> */}
        {/* </Swiper> */}
        </div>

        <SearchBox data={menu} />

        {menu.map((item) => {
          return (
            <article className={classes.meals} onClick={Cardshandler}>
              <img src={item.image} alt="" height={200} />
              <h1 className={classes.meals_header}>{item.name}</h1>
            </article>
          );
        })}
        <Footer />
      </div>
    </div>
  );
};

export default MenuDemo;
