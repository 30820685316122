import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import MenuDemo from './Components/Menu-Demo/Menu';
import Cards from './Components/Menu-Demo/Cards/Cards';

function App() {
  return (
  <Routes>
    <Route path='/' element={<MenuDemo />}/>
    <Route path='/menu/cards' element={<Cards />}/>
  </Routes>
  );
}

export default App;
