import React from 'react';
import "./footer.css";
import {FaCreativeCommonsBy, FaFacebookF, FaInstagram} from 'react-icons/fa';
import {BsTwitter} from 'react-icons/bs';

function Footer() {
  return (
    <footer>
      <a href='#' className='footer__logo'>ابو الشنب فوودز</a>

      <div className='footer__socials'>
      <a href='https://facebook.com' target='_blank'><FaFacebookF /> </a>
      <a href='https://instagram.com' target='_blank' ><FaInstagram /> </a>
      <a href='https://twitter.com' target='_blank'><BsTwitter /> </a>
      </div>

      <div className='footer_copyright'>
        <p>POWERED BY &copy;</p>
      </div>
    </footer>
  )
}

export default Footer;